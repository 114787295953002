import React from 'react';
import { useFeatureFlag } from '@pcf-engineering/gatsby-plugin-pcs-contentful-feature-flags/FeatureFlag/useFeatureFlag';
import { Script } from 'gatsby';

const CookieScript = () => {
  const isCookiesBannerEnabled = useFeatureFlag('COOKIE_BANNER');
  if (!isCookiesBannerEnabled) return null;
  return (
    <>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={process.env.ONETRUST_COOKIE_DOMAIN_SCRIPT}
        className={process.env.ONETRUST_COOKIE_DOMAIN_SCRIPT}
      />
      <Script
        src="/OneTrustCookieScript.js"
        type="text/javascript"
        className="onetrust"
      />
    </>
  );
};

export default CookieScript;
