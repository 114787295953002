module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.giftofchoice.ca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/pcf-engineering/pc-services/goc/pcs-goc-publicsite/src/locales","languages":["en","fr"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/themes/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5F2K5GL","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Gift of Choice","short_name":"GOC","start_url":"/","background_color":"#ee2e24","theme_color":"#ee2e24","display":"minimal-ui","prefer_related_applications":false,"icon":"src/images/pcf-goc-icon.png","icons":[{"src":"src/images/pcf-goc-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/pcf-goc-icon-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c41112d18caad304e0d260d912b35628"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"},{"name":"fr"}],"fields":[{"name":"id","store":true},{"name":"page","store":true,"attributes":{"boost":20}},{"name":"description","store":true,"attributes":{"boost":10}},{"name":"category","store":true,"attributes":{"boost":5}},{"name":"subcategory","store":true,"attributes":{"boost":5}},{"name":"url","store":true,"attributes":{"boost":5}}],"resolvers":{"ContentfulFaqItem":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../node_modules/@pcf-engineering/gatsby-plugin-akamai-bot-manager/gatsby-browser.js'),
      options: {"plugins":[],"whitelist":["giftofchoice.ca"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
